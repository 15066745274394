import useToken from '../useToken';
import { useCallback } from 'react';

function useGetFetchData() {
  const { token, error, loading } = useToken(); // Asumiendo que añades `loading` a tu hook useToken
  
  const GetData = useCallback(async (url) => {
    // Primero, manejar cualquier estado de error o carga
    if (loading) {
      console.log('Esperando la obtención del token...');
      return; // Puedes decidir retornar algún estado de carga si es necesario
    }
    
    if (error) {
      console.error('Error al obtener el token:', error);
      return; // Manejo del error de token
    }

    if (!token) {
      console.error('No se logró obtener un token', error);
      return; // Manejo de la ausencia de token
    }
    
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        console.error('Hubo un error al hacer la solicitud fetch', response.statusText);
        return; // Manejo del error de solicitud
      };

      return response.json();
    } catch (error) {
      console.error('Hubo un error al hacer la solicitud fetch:', error);
      return; // Manejo de errores en la solicitud fetch
    }
  }, [token, error, loading]); // Añadir `loading` a las dependencias si decides usarlo

  return GetData;
}

export default useGetFetchData;
