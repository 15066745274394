export function createMarker(poi, map) {
  const point = new window.google.maps.LatLng(poi.lat, poi.lng);

  const marker = new window.google.maps.Marker({
    position: point,
    map: map,
  });

  const infoWindow = new window.google.maps.InfoWindow({
    content: `<div style="color: black; font-size: 16px;">
                <strong>Nombre:</strong> ${poi.clienteNombre}
                <br>
                <strong>Dirección:</strong> ${poi.direccion}
                <br>
                <strong>Fecha:</strong> ${poi.fechaEntrega}
              </div>`
  });

  marker.addListener('click', () => {
    if (window.currentOpenInfoWindow) { 
      window.currentOpenInfoWindow.close();
    }
    infoWindow.open(map, marker);
  });

  return { marker, infoWindow };
}


export function createUserMarker(currentPosition, map) {
  const point = new window.google.maps.LatLng(currentPosition.lat, currentPosition.lng);

  const marker = new window.google.maps.Marker({
    position: point,
    map: map,
    icon: {
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 10,
      fillColor: '#4285F4',
      fillOpacity: 1,
      strokeWeight: 0,
    },
    zIndex: 999,
  });

  const infoWindow = new window.google.maps.InfoWindow({
    content: '<div style="color: black;"><strong>Estás aquí</strong></div>',
  });

  marker.addListener('click', () => {
    infoWindow.open(map, marker);
  });

  return { marker, infoWindow };
}


export function closeAllInfoWindows(markers) {
  markers.forEach(({ infoWindow }) => {
    infoWindow.close();
  });
}
