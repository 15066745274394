import React, { useEffect, useRef, useState } from "react";
import { MapContext } from './Contexts';
import '../CSS/GoogleMaps.css';

function GoogleMaps({ children }) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  


  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      const mapInitialization = new Promise((resolve) => {
        const newMap = new window.google.maps.Map(mapContainerRef.current, {
          mapId: 'b2b7c5b3c7e8f5b6',
        });

        window.google.maps.event.addListenerOnce(newMap, 'idle', () => {
          mapRef.current = newMap;
          resolve();
        });
      });

      mapInitialization.then(() => {
        setMapLoaded(true);
        mapRef.current.setOptions({heading: 90});
      });

    };
  }, [mapContainerRef, mapRef, setMapLoaded]);

  return (
    <MapContext.Provider value={{ map: mapRef.current, mapLoaded }}>
      <div ref={mapContainerRef} className="GoogleMapContainer" />
      {children}
    </MapContext.Provider>
  );
}

export default GoogleMaps;
