import React from 'react';
import { useMsal } from "@azure/msal-react";
import '../CSS/Home.css';

function Logout () {
  const { instance } = useMsal();

  const handleLogout = async () => {
    try {
      await instance.logoutRedirect();
    } catch (error) { 
      console.error('SessionButton: An error occurred during logout', error);
    }
  }


  return (
    <button className='home-boton' onClick={handleLogout}>Cerrar sesión</button>
  );
};

export default Logout;