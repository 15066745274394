import { useContext, useEffect, useRef } from 'react';
import { MapContext } from './Contexts';
import { createUserMarker } from './MarkerHelpers';

function UserLocationMarker() {
  const { map, mapLoaded } = useContext(MapContext);
  const userMarkerRef = useRef(null);

  useEffect(() => {
    if (!mapLoaded || !map) return;  // Asegúrate de que el mapa esté disponible
    
    const watchId = navigator.geolocation.watchPosition(position => {
      const currentPosition = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      if (!userMarkerRef.current) {
        userMarkerRef.current = createUserMarker(currentPosition, map);
      } else {
        userMarkerRef.current.marker.setPosition(currentPosition);
      }
    }, error => {
      console.error('Error al obtener la ubicación: ', error);
    }, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    });

    return () => {
      navigator.geolocation.clearWatch(watchId);
      if (userMarkerRef.current) {
        userMarkerRef.current.infoWindow.close();
        userMarkerRef.current.marker.setMap(null);
      }
    };
  }, [mapLoaded, map]);

  return null;
}

export default UserLocationMarker;