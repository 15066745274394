import React from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';
import '../CSS/LoginPage.css';

function LoginPage () {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className="login-container">
      <button className="login-button" onClick={handleLogin}>Iniciar sesión</button>
    </div>
  );
};

export default LoginPage;