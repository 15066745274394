 import { useContext, useEffect, useRef } from 'react';
 import { MapContext, PoiContext } from './Contexts';
 import { createMarker } from './MarkerHelpers';

 function POIMarkerManager() {
   const { map, mapLoaded } = useContext(MapContext);
   const pois = useContext(PoiContext);
   const markers = useRef([]);
   const openInfoWindow = useRef(); // Nueva referencia para la ventana de información abierta

   useEffect(() => {
     if (!mapLoaded || !map || !pois) return;

     markers.current.forEach(marker => marker.infoWindow.close());
     markers.current = pois.map(poi => {
       const { marker, infoWindow } = createMarker(poi, map);

       marker.addListener('click', () => {
         if (openInfoWindow.current) {
           openInfoWindow.current.close();
         }
         infoWindow.open(map, marker);
         openInfoWindow.current = infoWindow; // Guardar la ventana de información abierta
       });

       return { marker, infoWindow };
     });

     return () => {
       markers.current.forEach(({ marker, infoWindow }) => {
         infoWindow.close();
         marker.setMap(null);
       });
     };
   }, [mapLoaded, map, pois]);

   return null;
}

export default POIMarkerManager;