import { useContext, useEffect } from 'react';
import { MapContext, PoiContext } from './Contexts';

function AdjustMapBounds() {
  const { map, mapLoaded } = useContext(MapContext);
  const pois = useContext(PoiContext);
  const factor = 0.002;

  useEffect(() => {
    if (!mapLoaded || !map || pois.length === 0) return;

    let lat = 0;
    let lng = 0;
  
    const bounds = new window.google.maps.LatLngBounds();

    pois.forEach(poi => {
      const point = new window.google.maps.LatLng(poi.lat, poi.lng);
      bounds.extend(point);
    });

    const center = bounds.getCenter();
    const boundsP2 = new window.google.maps.LatLngBounds();

    if (pois.length === 1) {
      boundsP2.extend(new window.google.maps.LatLng(center.lat() + factor, center.lng() + factor));
      boundsP2.extend(new window.google.maps.LatLng(center.lat() - factor, center.lng() - factor));
    } else {
      pois.forEach(poi => {
        if (poi.lat > center.lat() && poi.lng > center.lng()) {
          lat = poi.lat + factor;
          lng = poi.lng + factor;
        }else {
          if (poi.lat < center.lat() && poi.lng > center.lng()) {
            lat = poi.lat - factor;
            lng = poi.lng + factor;
          }else {
            if (poi.lat < center.lat() && poi.lng < center.lng()) {
              lat = poi.lat - factor;
              lng = poi.lng - factor;
            }else {
              if (poi.lat > center.lat() && poi.lng < center.lng()) {
                lat = poi.lat + factor;
                lng = poi.lng - factor;
              }
            };
          }
        }
        const point = new window.google.maps.LatLng(lat, lng);
        boundsP2.extend(point);
      });
    }

    map.fitBounds(boundsP2, { padding: 0 });

    // Aplica la restricción después de un breve retraso
    setTimeout(() => {
      map.setOptions({
        restriction: {
          latLngBounds: boundsP2,
          strictBounds: false,
        },
      });
    }, 500);  // Ajusta el tiempo de retraso según sea necesario

  }, [mapLoaded, map, pois]);

  return null;
}

export default AdjustMapBounds;