import React from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import '../CSS/Footer.css';


function Footer() {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const userName = accounts?.[0]?.name;

  return (
    <footer className='footer'>
      © 2024 MasterFic Delivery. Todos los derechos reservados. 
      {isAuthenticated && userName && <div className='usuarioAutenticado'>Bienvenido, {userName}</div>}
    </footer>
  );
}

export default Footer;