import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "9c0f00ea-1432-4dba-aab2-1b4e36ecde39",
    authority: "https://login.microsoftonline.com/de1539bd-3a7c-48b1-9cad-33fd14fde7e5",
    redirectUri: window.location.hostname === "localhost" ? "http://localhost:3000" : "https://Delivery.MasterFic.com",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {	
    loggerOptions: {	
      loggerCallback: (level, message, containsPii) => {	
        if (containsPii) {		
          return;		
        }		
        switch (level) {
          case LogLevel.Error: console.error(message); return;
          case LogLevel.Info: console.info(message); return;
          case LogLevel.Verbose: console.debug(message); return;
          case LogLevel.Warning: console.warn(message); return;
          default: return;
        }	
      }	
    }	
  }
};

export const loginRequest = {
  scopes: ['api://9208ce53-8d03-4af1-a9df-618f6ed24bb9/read.write', 'user.read'],
  // Puedes agregar más propiedades de configuración aquí
};


