import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";
import './App.css';
import Home from './D_FrameworksAndDrivers/UI/Home';
import LoginPage from './D_FrameworksAndDrivers/UI/LoginPage';
import Footer from './D_FrameworksAndDrivers/UI/Footer';
import Poi from './D_FrameworksAndDrivers/UI/Poi/Poi';

function App() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Router>
      <div className='App'>
      {isAuthenticated ? (
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/PoiHoy' element={<Poi especificarEntrega = 'Hoy' />} />
            <Route path='/PoiProxima' element={<Poi especificarEntrega = 'Proxima' />} />
          </Routes>
        ) : (
          <LoginPage />
        )}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
