import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/Home.css';
import Logout from './Logout';

function Home() {
  return (
    <div className="home-contenedor">
      <Link to='/PoiHoy' className="home-boton" >Mapa entrega de hoy</Link>
      <Link to='/PoiProxima' className="home-boton" >Mapa proxima entrega </Link>
      <Logout />
    </div>
  );
}

export default Home;
