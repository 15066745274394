import { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";

export default function useToken() {
    const { instance, accounts, inProgress } = useMsal();
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Iniciar con loading true
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 3; // Hacer esto una constante fija por ahora

    useEffect(() => {
        async function acquireToken() {
            if (inProgress !== "none" || accounts.length === 0) {
                if (accounts.length === 0) {
                    setError("No hay cuentas disponibles.");
                }
                setLoading(false);
                return;
            }

            try {
                const request = {
                    scopes: ['api://9208ce53-8d03-4af1-a9df-618f6ed24bb9/read.write'],
                    account: accounts[0]
                };

                const responseToken = await instance.acquireTokenSilent(request);
                if (responseToken && responseToken.accessToken) {
                    setToken(responseToken.accessToken);
                    setError(null);
                    setRetryCount(0); // Reset retry count on success
                } else {
                    throw new Error("Token obtenido es inválido o vacío.");
                }
            } catch (error) {
                console.error('Error al obtener el token:', error);
                setError('Hubo un error al obtener el token: ' + error.message);
                if (retryCount < maxRetries) {
                    setRetryCount(retryCount + 1); // Increment retry count
                } else {
                    setError("Error al obtener el token después de varios intentos.");
                }
            } finally {
                setLoading(false); // Asegurar que loading siempre se ponga a false
            }
        }

        acquireToken();
    }, [instance, accounts, inProgress, retryCount, maxRetries]);

    return { token, error, loading };
}

