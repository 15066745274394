import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

function GoogleMapsWrapper ({ children }) {

  // Ideally we want the apiKey to be fetch from an environment variable
  //const apiKey = 'AIzaSyBSJCPTaPO1X9KK28VaPXBiPPf4vOkMQqg';
  const apiKey = 'AIzaSyBDq_EtxPFjXnfjhGy6NjFK3j5nYQBIaqg';
  //console.log('process.env.REACT_APP_GOOGLE_MAPS_API_KEY', process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  //const apiKey =  process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  if (!apiKey) {
    return <div>Cannot display the map: google maps api key missing</div>;
  }

  return (
    <Wrapper apiKey={apiKey}>
      {children}
    </Wrapper>
  );
};

export default GoogleMapsWrapper;