import React from 'react';
import '../CSS/MapInput.css'

function MapInput({ search, setSearch }) {
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const clearSearch = () => {
    setSearch(''); // Limpia el campo de búsqueda
  }

  return (
    <div className='MapInputContainer'>
      <input className='MapInputStyle'
        type="text"
        placeholder="Buscar Cliente..."
        value={search}
        onChange={handleSearchChange}
      />
      {search && (
        <button
          className='ButtonStyle'
          onClick={clearSearch}
          style={{
            opacity: search ? 1 : 0, // Solo visible cuando hay texto
          }}>
          <strong>&#x2715;</strong> {/* Este es un caracter Unicode para la "X", que funciona como ícono de cierre */}
        </button>
      )}
    </div>
  );
}

export default MapInput;
