import React, { useState, useEffect } from 'react';
import '../../CSS/BotonRegresar.css';
import { useNavigate } from 'react-router-dom';
import { PoiContext } from '../../GoogleMaps/Contexts'
import useGetFetchData from '../../../C_InterfaceAdapters/Network/Fetch/useGetFetchData';
import GoogleMapsWrapper from '../../../D_FrameworksAndDrivers/GoogleMaps/GoogleMapsWrapper ';
import GoogleMaps from '../../../D_FrameworksAndDrivers/GoogleMaps/GoogleMaps';
import MapInput from '../../../D_FrameworksAndDrivers/GoogleMaps/MapInput';
 import UserLocationMarker from '../../GoogleMaps/UserLocationMarker';
 import AdjustMapBounds from '../../GoogleMaps/AdjustMapBounds';
 import PoimarkerManager from '../../GoogleMaps/POIMarkerManager';
// import DeviceOrientation from '../../../D_FrameworksAndDrivers/GoogleMaps/DeviceOrientation';
// import SetMapOrientation from '../../../D_FrameworksAndDrivers/GoogleMaps/SetMapOrientation';

function Poi({ especificarEntrega }) {
  let urlAdicional = '/Actual';

  if (especificarEntrega === 'Proxima') {
    urlAdicional = '/Proxima';
  }

  const urlBase = 'https://ApiMasterFicControlFlota.azurewebsites.net/GetPoi';
  //const urlBase = 'https://localhost:7231/GetPoi'
  const url = `${urlBase}${urlAdicional}`;
  const getPoi = useGetFetchData();
  const [poiObject, setPoiObject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  //const [orientation, setOrientation] = useState(0);
  const navigate = useNavigate();

  //const handleOrientationChange = (alpha) => {
  //  setOrientation(alpha);
  //};

  useEffect(() => {
    async function fetchGetPois() {
      setIsLoading(true);
      try {
        const data = await new Promise((resolve, reject) => {
          getPoi(url)
            .then(data => {
              if (!data || data.length === 0) {
                resolve('No se encontraron registros');
              } else {
                resolve(data);
              }
            })
            .catch(error => {
              reject(error);
            });
        });

        setPoiObject(data);
      } catch(error) {
        console.error('Error al obtener los POIs:', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchGetPois();
  }, [url, getPoi, setPoiObject, setIsLoading]);


  if (isLoading) {
      return <div>Cargando...</div>;
  } else if (!poiObject || poiObject === 'No se encontraron registros') {
    return <div>No se encontraron registros</div>;
  } else{
    const filteredPois = poiObject.filter(p =>
      p.clienteNombre.toLowerCase().includes(search.toLowerCase())
    );

    return (
      <>
        <PoiContext.Provider value={filteredPois}>
          <button className='boton-regresar' onClick={() => navigate('/')}>Regresar</button>
          <MapInput search={search} setSearch={setSearch} />
          <GoogleMapsWrapper>
            <GoogleMaps>
              <AdjustMapBounds />
              <UserLocationMarker />
              <PoimarkerManager />
              {/* <DeviceOrientation onOrientationChange={handleOrientationChange} />
              <SetMapOrientation orientation={orientation} /> */}
            </GoogleMaps>
          </GoogleMapsWrapper>
        </PoiContext.Provider>
      </>
    );
  };
 }

export default Poi;
